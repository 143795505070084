import isNull from 'lodash-es/isNull';
import isUndefined from 'lodash-es/isUndefined';
import isEmpty from 'lodash-es/isEmpty';
import * as d3 from 'd3-format';
import { TraderEventType } from './eventsConstant';
import { getNativeUtils, redirectToPage } from './nativeUtils';
import router from 'next/router';
import { ROUTE_NAMES } from './routes';
import { getAuthData, getStorageItem, getWin, setStorageItem } from './storage';
import { STORAGE_KEYS } from './keys';
import {
  ANDROID_VERSION_FOR_SMS_PULL,
  GMAP_URLS,
  SMS_PULL_VALIDITY,
} from 'constants/common';

export const isValidData = <T>(
  data: T | null | undefined
): data is NonNullable<T> =>
  !isNull(data) || !isUndefined(data) || !isEmpty(data);

export const onlyInLeft = <T, Y>(
  left: T[] = [],
  right: Y[] = [],
  compareFunction: (l: T, r: Y) => boolean
): T[] =>
  [...left].filter(
    (leftValue) =>
      !right.some((rightValue) => compareFunction(leftValue, rightValue))
  );

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const ret = {} as Pick<T, K>;
  keys.forEach((key) => {
    ret[key] = obj[key];
  });
  return ret;
}

export function sanitizePhoneNumber(contactNumber: string) {
  if (contactNumber) {
    contactNumber.replace(/\s/g, '');
  }
  return contactNumber?.includes('+91') ? contactNumber : `+91${contactNumber}`;
}

const INR_FORMAT: d3.FormatLocaleDefinition = {
  decimal: '.',
  thousands: ',',
  grouping: [3, 2, 2, 2],
  currency: ['₹', ''],
};

d3.formatDefaultLocale(INR_FORMAT);

export const inrFormat = d3.format('$,');

export const onVisible = (
  element: Element | null,
  callback: (element: Element | null) => void
) => {
  if (element !== null) {
    new IntersectionObserver(
      (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.intersectionRatio > 0) {
            callback(element);
            observer.disconnect();
          }
        });
      }
    ).observe(element);
  }
};

const getAndroidVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  const match = ua.match(/android\s([0-9\.]*)/i);
  return match ? match[1] : undefined;
};

export const goToEKyc = (triggerEvent: Function) => {
  triggerEvent(TraderEventType.Generic_event, {
    eventName: 'ekyc_initiate',
  });

  const dashboardData = JSON.parse(
    window?.localStorage?.getItem(STORAGE_KEYS.DASHBOARD_DATA) || 'null'
  );
  const consent =
    dashboardData?.userRealmIdentifierInfo?.isPermissionConsentAccepeted;
  const getPermission = JSON.parse(
    window?.localStorage?.getItem('consent') || 'null'
  );
  const appVersion = JSON.parse(
    window?.localStorage?.getItem(STORAGE_KEYS.APP_BUILD_VERSION) || 'null'
  );
  if (!consent && !getPermission && appVersion > 164) {
    router.push(ROUTE_NAMES.CONSENT_EKYC);
  } else {
    const androidVersion = parseInt(getAndroidVersion() as string, 10);
    if (androidVersion >= 13) {
      redirectToPage('/e-kyc/select', 'e-kyc');
    } else {
      redirectToPage('/e-kyc/select', 'Kyc');
    }
  }
};

export const isNativeFeatureAvailible = (featureReleaseVersion: number) => {
  const androidVersion = +(getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION) || 0);
  if (!getStorageItem(STORAGE_KEYS.IS_ANDROID)) return true;
  return (
    getNativeUtils() &&
    getStorageItem(STORAGE_KEYS.IS_ANDROID) == true &&
    androidVersion >= featureReleaseVersion
  );
};

export const isElementInViewport = (el: HTMLDivElement | null) => {
  if (!el) return false;
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const cookieparser = (cookie: string) => {
  const cookies = cookie.split(';');
  const result: { [key: string]: string } = {};
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name: string = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie;
    const val = eqPos > -1 ? cookie.substr(eqPos + 1).trim() : '';
    result[name] = val;
  }
  return result;
};

export const handleRedirectionTrader = (url: string, pageName?: string) => {
  if (url?.includes('trader-new')) {
    const routerUrl = url.split('trader-new');
    router.push(routerUrl[1]);
  } else if (url) {
    redirectToPage(url, pageName);
  }
};

export const checkNinjaOneURL = (
  queryParams: URLSearchParams,
  dispatch: any,
  treatments: SplitIO.TreatmentsWithConfig
) => {
  const { contactNumber } = getAuthData() || {};
  const ninjaOneInvitedPhoneNumber = queryParams.get('ninjaoneNumber');
  const openNinjaOne = () => {
    const timeStamp = Date.now();
    const ninjaOneURL = `${process.env.NEXT_PUBLIC_NINJA_ONE_URL}${window.location.search}`;
    const separator = ninjaOneURL?.includes('?') ? '&' : '?';
    const url = `${ninjaOneURL}${separator}ntcInitialTimeStamp=${timeStamp}`;
    getNativeUtils()?.openWebview(url, 'NinjaOneMfe', getWin().localStorage);
  };
  if (
    ninjaOneInvitedPhoneNumber &&
    ninjaOneInvitedPhoneNumber !== contactNumber
  ) {
    dispatch({ type: 'toggleNinjaOneLogout', payload: true });
  } else if (
    ninjaOneInvitedPhoneNumber &&
    ninjaOneInvitedPhoneNumber === contactNumber
  ) {
    if (
      treatments?.view_ninja_one?.treatment === 'on' ||
      treatments?.view_ninja_paysure?.treatment === 'on'
    ) {
      openNinjaOne();
    }
  }
};

export function getISTFormattedDateTime() {
  const date = new Date();
  const pad = (num: any) => String(num).padStart(2, '0');
  const istOffset = 5 * 60 + 30;
  const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
  const istTime = new Date(utcTime + istOffset * 60000);

  const year = istTime.getFullYear();
  const month = pad(istTime.getMonth() + 1);
  const day = pad(istTime.getDate());
  const hours = pad(istTime.getHours());
  const minutes = pad(istTime.getMinutes());
  const seconds = pad(istTime.getSeconds());

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+05:30`;

  return formattedDateTime;
}

export function formatDateToReadable(dateString: string) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

  return formattedDate;
}

export const evaluateIsTrue = (str: string | boolean) => {
  return str && (str === true || str.toLowerCase() === 'true');
};
export function numberToString(number: number): string {
  const numbers: { [key: number]: string } = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    10: 'ten',
  };

  return numbers[number] || '';
}
export const differnceFromToday = (lastTxnDate: string) => {
  const receivedDate = new Date(lastTxnDate);
  const today = new Date();
  const timeDifference = today - receivedDate;
  const differenceInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return differenceInDays;
};

// Function to check if 15 days have passed from last SMS pull. If yes, trigger native SMS pull
export const checkForSMSPull = () => {
  const androidVersion = getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION) || 0;
  const isAndroid = getStorageItem(STORAGE_KEYS.IS_ANDROID) || false;
  const updatedNextSMSPullDate = new Date();
  const futureDateForSMSPull = getStorageItem(STORAGE_KEYS.NEXT_SMS_PULL);
  const currentDate = new Date();
  updatedNextSMSPullDate.setDate(currentDate.getDate() + SMS_PULL_VALIDITY); // After Native SMS pull is done, setting date after 15 days in localStorage for next SMS pull validity check
  if (
    !isAndroid ||
    !androidVersion ||
    +androidVersion <= ANDROID_VERSION_FOR_SMS_PULL
  ) {
    return;
  }

  if (!!futureDateForSMSPull) {
    const nextSMSPullDate = new Date(futureDateForSMSPull);
    if (currentDate < nextSMSPullDate) {
      // If there is still time for next SMS pull date, then don't do anything
      return;
    }
  }
  getNativeUtils()?.askSMSReadPermission(); // Native function call to start Android SMS pull

  setStorageItem(
    STORAGE_KEYS.NEXT_SMS_PULL,
    updatedNextSMSPullDate.toISOString()
  );
};

export const loadClarity = () => {
  const clarityScript = document.createElement('script');
  clarityScript.type = 'text/javascript';
  clarityScript.async = true;
  clarityScript.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_KEY}");
  `;
  document.head.appendChild(clarityScript);

  return () => {
    document.head.removeChild(clarityScript);
  };
};

export const loadGmapScript = () => {
  const gmapScript = document.createElement('script');
  gmapScript.type = 'text/javascript';
  gmapScript.async = true;
  gmapScript.src = GMAP_URLS;
  document.head.appendChild(gmapScript);
  return () => {
    document.head.removeChild(gmapScript);
  };
};
